.planner-container {
  height: 100%;
}
.planner-container > .row {
  height: 100%;
}
.planner-container .map-container {
  width: 100%;
  height: 100%;
}
.planner-container .map-container .leaflet-container {
  height: 100%;
  width: 100%;
  cursor: grab;
}
.planner-container .map-container .leaflet-container .leaflet-control-attribution a:first-child {
  display: none;
}
.planner-container .map-container.toolActive .leaflet-container {
  cursor: default;
}

.toolEnd-agent {
  border-radius: 50%;
}