.home-container {
  color: white;
}
.home-container .banner {
  background-image: url('/images/banner.png');
  min-height: 150px;
  max-height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}
.home-container .banner h1,
.home-container .banner p {
  color: white;
}
@media (min-width: 768px) {
  .home-container .banner {
    padding-top: 30px;
    min-height: 230px;
  }
}
@media (min-width: 1024px) {
  .home-container .banner {
    padding-top: 75px;
    min-height: 320px;
  }
}
@media (min-width: 1600px) {
  .home-container .banner {
    padding-top: 210px;
    min-height: 600px;
  }
}
.home-container .branding-background {
  background-color: #222222;
  border-radius: 5px;
  color: white;
}
.home-container .planner-preview {
  width: 100%;
}
