html,
body {
  height: 100%;
  background-color: #111111;
}
body {
  overflow-y: scroll;
}
body,
#root,
.app {
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.4;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #111111;
  color: #cccccc;
}
hr {
  border-color: white !important;
}
.navbar {
  background-color: #111111;
  border: none;
  border-radius: 0;
  min-height: 70px;
  margin-bottom: 0;
}
.navbar-right {
  display: flex;
  flex-direction: row;
}
.navbar-rightSocial {
  margin-right: 1rem;
}
.navbar .nav-item {
  max-height: 69px;
  overflow: hidden;
}
.navbar a,
.navbar i {
  color: #ffffff;
}
.navbar a.active,
.navbar i.active {
  color: #ff7811;
}
.navbar .nav a {
  padding: 25px;
  text-decoration: none;
  text-align: center;
  line-height: 2em;
}
.navbar i {
  font-size: 1.6em;
}
.btn-hide {
  display: none;
}
.fa-inverse {
  color: #fff;
}
.container * {
  color: #ffffff;
}
