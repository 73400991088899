.userBar {
  color: #ffffff;
}
.userBar .image-container {
  display: inline;
  max-height: 3rem;
}
.userBar .image-container img {
  display: inline;
  max-height: 3rem;
}
.userBar .user-action-link {
  color: #ffffff;
  cursor: pointer;
}
