.sidebar {
  background-color: #222222;
  color: white;
}
.sidebar .tool-category-headings {
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}
.sidebar .tool-category-headings .tool-category-header {
  text-align: center;
  cursor: pointer;
}
.sidebar .tool-category-headings .tool-category-header.active {
  color: #222222;
  background-color: white;
}
.sidebar .btn-tool {
  border: 2px solid #333333;
}
.sidebar .btn-tool.active {
  border-color: #ffffff;
}
