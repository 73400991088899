.article.featured {
    height: 250px;
}

.article.featured .content {
    height: 100%;
    width: 100%;
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
}

.article.featured .title {
    align-self: flex-end;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 18%);
    width: 100%;
}